import { ChevronDownIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Center,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Stack,
	Stat,
	StatGroup,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from "@chakra-ui/react";
import { type StoreData, db } from "@flowby/firebase";
import { useEffect, useState } from "react";
import { IoOpenOutline, IoTrashBinOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import { useCollData } from "../../libs/firebaseHooks";
import { useScreenSize } from "../../libs/native";
import ConfirmAlert from "../shared/ConfirmAlert";
import ErrorComponent from "../shared/Error";
import Loader from "../shared/Loader";
import ModalContainer from "../shared/ModalContainer";
import { useToast } from "../shared/Toast";
import CreateStoreForm from "./CreateStoreForm";

const mapStatusToNumber = (status: string) => {
	if (status === "active") {
		return 3;
	}
	if (status === "pending") {
		return 2;
	}
	if (status === "inactive") {
		return 1;
	}
	return 0;
};

const orderStores = (storeA: StoreData, storeB: StoreData) => {
	return mapStatusToNumber(storeB.status) - mapStatusToNumber(storeA.status);
};

export default function Stores() {
	const screenSize = useScreenSize();
	const createStoreDisclosure = useDisclosure();
	const deleteStoreDisclosure = useDisclosure();
	const toast = useToast();
	const [storesData, storesDataLoading] = useCollData(db.getStoresRef(), []);
	const [queueCounts, setQueueCounts] = useState<
		{ [key: string]: number } | undefined
	>(undefined);
	const [deleteStoreLoading, setDeleteStoreLoading] = useState<boolean>(false);
	const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(
		undefined,
	);
	const [selectedStoreStatus, setSelectedStoreStatus] = useState<
		"active" | "inactive" | "pending" | undefined
	>(undefined);
	const deactivateStoreDisclosure = useDisclosure();

	useEffect(() => {
		if (storesData && !queueCounts) {
			const stores = storesData.map((store) => {
				return store.id;
			});
			getQueueCounts(stores).then((queueCounts) => {
				setQueueCounts(queueCounts);
			});
		}
	}, [storesData]);

	const deleteStore = async () => {
		setDeleteStoreLoading(true);
		try {
			if (!selectedStoreId) {
				throw new Error("No store selected.");
			}
			await db.deleteStore(selectedStoreId);
			toast("success", `Deleted store with id ${selectedStoreId}.`);
			deleteStoreDisclosure.onClose();
		} catch (e) {
			toast(
				"error",
				`Failed to delete store with id ${selectedStoreId}.`,
				"FlowbyAdmin/delete-store-error",
				e,
			);
		}
		setDeleteStoreLoading(false);
	};

	const updateStoreStatus = async () => {
		try {
			if (!selectedStoreId) {
				throw new Error("No store selected.");
			}
			await db.updateStore(selectedStoreId, { status: selectedStoreStatus });
			toast("success", `Store status changed to ${selectedStoreStatus}.`);
			deactivateStoreDisclosure.onClose();
		} catch (e) {
			toast(
				"error",
				`Failed to change store status to ${selectedStoreStatus}.`,
				"FlowbyAdmin/edit-store-status-error",
				e,
			);
		}
	};

	const mapStatus = (status: string) => {
		if (status === "active") {
			return "green.500";
		}
		if (status === "pending") {
			return "yellow.500";
		}
		if (status === "inactive") {
			return "red.500";
		}
		return "gray.500";
	};

	const getQueueCounts = async (stores: string[]) => {
		const counts: { [key: string]: number } = {};
		const storeCounts = stores.map(async (store) => {
			const queuesData = await db.getQueues(store);
			counts[store] = queuesData.size;
			return;
		});
		await Promise.all(storeCounts);
		return counts;
	};

	if (storesDataLoading) {
		return <Loader />;
	}

	if (!storesData) {
		return <ErrorComponent />;
	}

	const activeStores = storesData.filter((store) => store.status === "active");
	const activeQueues = activeStores
		.map((store) => queueCounts?.[store.id])
		.filter((value) => value !== undefined)
		.reduce((partialSum, a) => {
			const sum = partialSum as number;
			const acc = a as number;
			return sum + acc;
		}, 0);
	return (
		<>
			<ModalContainer
				isOpen={createStoreDisclosure.isOpen}
				onClose={createStoreDisclosure.onClose}
				header="Create new store"
				content={<CreateStoreForm onFinish={createStoreDisclosure.onClose} />}
			/>
			<ConfirmAlert
				disclosure={deleteStoreDisclosure}
				headerText="Delete store"
				questionText={`Are you sure you want to delete the store with id ${selectedStoreId}?`}
				noText="No"
				yesText="Yes"
				yesAction={deleteStore}
				yesLoading={deleteStoreLoading}
				validateString={selectedStoreId}
				validatePlaceholder="Type the store id to confirm deletion."
			/>
			<ConfirmAlert
				disclosure={deactivateStoreDisclosure}
				headerText={
					selectedStoreStatus === "inactive"
						? "WARNING: Deactivate store"
						: "Change store status"
				}
				questionText={
					selectedStoreStatus === "inactive"
						? `Are you sure you want to set the store ${selectedStoreId} to ${selectedStoreStatus}? This will deactivate the store and prevent any new customers from entering the queues.`
						: `Are you sure you want to set the store ${selectedStoreId} to ${selectedStoreStatus}?`
				}
				noText="No"
				yesText="Yes"
				yesAction={updateStoreStatus}
			/>
			<Stack spacing={2}>
				<StatGroup>
					<Stat>
						<StatLabel>Active Stores</StatLabel>
						<StatNumber>{activeStores.length}</StatNumber>
					</Stat>
					<Stat>
						<StatLabel>Active Queues</StatLabel>
						<StatNumber>{activeQueues}</StatNumber>
					</Stat>
				</StatGroup>
				<Box>
					<Button size="md" onClick={createStoreDisclosure.onOpen}>
						Create new store
					</Button>
				</Box>
				{storesData && screenSize === "desktop" && (
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>ID</Th>
								<Th>Country</Th>
								<Th>Language</Th>
								<Th>Queues</Th>
								<Th>Status</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{storesData.sort(orderStores).map((store) => {
								return (
									<Tr key={store.id}>
										<Td>{store.name}</Td>
										<Td>{store.id}</Td>
										<Td>{store.country}</Td>
										<Td>{store.lang}</Td>
										<Td>
											{queueCounts && queueCounts[store.id] !== undefined ? (
												queueCounts[store.id]
											) : (
												<Spinner />
											)}
										</Td>
										<Td>
											<Center>
												<Menu>
													<MenuButton
														size="sm"
														variant="outline"
														as={Button}
														rightIcon={<ChevronDownIcon />}
													>
														<Box
															w={4}
															h={4}
															borderRadius="xl"
															backgroundColor={mapStatus(store.status)}
														/>
													</MenuButton>
													<MenuList>
														<MenuItem
															onClick={() => {
																setSelectedStoreStatus("active");
																setSelectedStoreId(store.id);
																deactivateStoreDisclosure.onOpen();
															}}
														>
															<Box
																w={4}
																h={4}
																mr={1}
																borderRadius="xl"
																backgroundColor="green.500"
															/>
															Active
														</MenuItem>
														<MenuItem
															onClick={() => {
																setSelectedStoreStatus("pending");
																setSelectedStoreId(store.id);
																deactivateStoreDisclosure.onOpen();
															}}
														>
															<Box
																w={4}
																h={4}
																mr={1}
																borderRadius="xl"
																backgroundColor="yellow.500"
															/>
															Pending
														</MenuItem>
														<MenuItem
															onClick={() => {
																setSelectedStoreStatus("inactive");
																setSelectedStoreId(store.id);
																deactivateStoreDisclosure.onOpen();
															}}
														>
															<Box
																w={4}
																h={4}
																mr={1}
																borderRadius="xl"
																backgroundColor="red.500"
															/>
															Inactive
														</MenuItem>
													</MenuList>
												</Menu>
											</Center>
										</Td>
										<Td>
											<Stack direction="row">
												<IconButton
													colorScheme="red"
													size="sm"
													aria-label="Visit"
													icon={<IoTrashBinOutline />}
													onClick={() => {
														setSelectedStoreId(store.id);
														deleteStoreDisclosure.onOpen();
													}}
												/>
												<RouterLink to={`/${store.id}`} target="_blank">
													<IconButton
														size="sm"
														aria-label="Visit"
														icon={<IoOpenOutline />}
													/>
												</RouterLink>
											</Stack>
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				)}
				{storesData && (screenSize === "mobile" || screenSize === "tablet") && (
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{storesData.sort(orderStores).map((store) => {
								return (
									<Tr key={store.id}>
										<Td>{store.name}</Td>
										<Td>
											<RouterLink to={`/${store.id}`}>
												<IconButton
													size="sm"
													aria-label="Visit"
													icon={<IoOpenOutline />}
												/>
											</RouterLink>
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				)}
			</Stack>
		</>
	);
}
