import { useToast as useChakraToast } from "@chakra-ui/react";

export const useToast = () => {
	const toast = useChakraToast();

	const showToast = (
		type: "success" | "error" | "warning" | "info",
		title: string | null,
		origin?: string, // This is the calling component for better error logging
		error?: unknown,
	) => {
		let finalTitle: string;
		if (title === null) {
			finalTitle =
				"Sorry, something went wrong. Please try again or contact support at hello@flowby.io.";
		} else {
			finalTitle = title;
		}
		if (type === "error") {
			// TODO: Send error somewhere to keep track?
		}
		console.log("TOASTING");
		const h = toast({
			title: finalTitle,
			status: type,
			duration: 5000,
			position: "top",
		});
		console.log("id", h);
	};

	return showToast;
};
