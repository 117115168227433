import { db, firebase } from "@flowby/firebase";
import { Outlet, useParams } from "react-router-dom";
import FlowbyError from "../components/shared/Error";
import { useDocData } from "../libs/firebaseHooks";
import { useLoading } from "../hooks/useLoading";
import { theme } from "@flowby/general";
import { Button, Flex, Heading } from "@chakra-ui/react";

// Will show an error if the store is inactive, meaning they haven't paid
export const StoreStatusGuard = () => {
	const params = useParams();
	const store = params.store as string;
	const [storeData, storeLoading] = useDocData(db.getStoreRef(store), [store]);
	if (useLoading(storeLoading)) return null;

	if (!storeData) {
		return <FlowbyError />;
	}

	if (storeData.status === "inactive") {
		return (
			<>
				<FlowbyError
					fullscreen={true}
					text="Your account is not active. Please contact us at hello@flowby.io or +46 8519 424 09 to reactivate it."
				/>
				<Flex justifyContent="center" mt={6}>
					<Button
						onClick={() => {
							// redirect to the admin app
							firebase.auth.signOut();
						}}
					>
						Logout
					</Button>
				</Flex>
			</>
		);
	}

	return storeData.status === "pending" ? (
		<>
			<div
				data-testid="trial-mode-banner"
				style={{
					backgroundColor: theme.colors.colors.yellow[200],
					color: theme.colors.colors.gray[800],
					textAlign: "center",
					position: "absolute",
					width: "100%",
					top: 0,
					left: 0,
					zIndex: 1000,
					lineHeight: "1",
				}}
			>
				<Heading fontSize="xs">TRIAL MODE</Heading>
			</div>
			<Outlet />
		</>
	) : (
		<Outlet />
	);
};
