const isProdOrDev = process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "dev";

const getMessageString = (component: string, message: string) => {
  return `${component} - ${message}`;
};

export const logger = {
  info: (message: string, component: string) => {
    console.log(getMessageString(component, message));
  },

  warn: (message: string, component: string, data?: Record<string, any>) => {
    console.warn(getMessageString(component, message), data);

    if (isProdOrDev && window.Sentry) {
      window.Sentry.captureMessage(getMessageString(component, message), {
        level: 'warning',
        extra: data
      });
    }
  },

  error: (message: string, component: string, originalError?: unknown, data?: Record<string, any>) => {
    const error = originalError instanceof Error 
      ? originalError 
      : new Error(message);

    console.error(getMessageString(component, message), error, data);

    if (isProdOrDev && window.Sentry) {
      window.Sentry.captureException(error, {
        extra: {
          message: getMessageString(component, message),
          context: data
        }
      });
    }
  },

  debug: (message: string, component: string) => {
    if (!isProdOrDev) {
      console.debug(getMessageString(component, message));
    }
  }
};